import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Protezy ruchome'}>
      <Helmet title={"Protezy ruchome - Pogotowie stomatologiczne Dent&Beauty"} />
    </OfferBase>
  )
}

export default Offer
